import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { EngBreakpointsModule } from '@shared/core/directives/breakpoints/breakpoints.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from '@pages/components/footer/footer.module';

@NgModule({
  declarations: [MainComponent],
  imports: [CommonModule, MainRoutingModule, EngBreakpointsModule, TranslateModule, FooterModule],
})
export class MainModule {}
