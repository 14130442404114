import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthState } from './state/auth/auth.state';
import { CurrentUserState } from './state/current-user/current-user.state';
import { AddGameService } from './pages/account/components/add-game/add-game.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanActivateService implements CanActivate {
  private availableOnlyWithoutAuth: string[] = ['/login', '/registration', '/verification-phone'];
  private availableWithoutAuth: string[] = ['/game/play'];

  private accountPage = '/account';
  private firstScreen = '/';

  private pageSubscription = '/account/payment';
  private pagesOnlyWithSubscribe = ['/account/add-bingo'];

  constructor(
    private router: Router,
    private readonly authState: AuthState,
    private currentUserState: CurrentUserState,
    private addGameService: AddGameService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    const isAvailableOnlyWithoutAuthRouter = !!this.availableOnlyWithoutAuth.find((router) => {
      return url === '/' || url.includes(router);
    });
    const isAvailableWithoutAuthRouter = !!this.availableWithoutAuth.find((router) => {
      return url.includes(router);
    });

    if (isAvailableWithoutAuthRouter) {
      return true;
    }

    const isAuthExpired = this.authState.isAuthorizationExpired;
    // const isLearner = this.currentUserState.role === Role.learner;
    if (isAvailableOnlyWithoutAuthRouter && !isAuthExpired) {
      return this.currentUserState
        .show()
        .pipe(mergeMap(() => of(this.router.parseUrl(this.accountPage))));
    }

    const pageOnlyWithSubscribe = this.pagesOnlyWithSubscribe.find(
      (pageUrl: string) => url.indexOf(pageUrl) + 1,
    );
    if (pageOnlyWithSubscribe) {
      if (!this.addGameService.isActivePlan()) {
        return this.router.parseUrl(this.pageSubscription);
      }
    }

    return (
      isAvailableOnlyWithoutAuthRouter || !isAuthExpired || this.router.parseUrl(this.firstScreen)
    );
  }

  canActivateChild(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUserState.show().pipe(mergeMap(() => of(true)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class CanActivateUserService implements CanActivate {
  constructor(
    private router: Router,
    private readonly authState: AuthState,
    private currentUserState: CurrentUserState,
    private addGameService: AddGameService,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUserState.show().pipe(mergeMap(() => of(true)));
  }
}
