import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { NgModule } from '@angular/core';
import { CanActivateService } from 'app/can-activate.service';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [CanActivateService],
    children: [
      {
        path: '',
        loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'login',
        loadChildren: () => import('../login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'verification-email',
        loadChildren: () =>
          import('../verification-email/verification-email.module').then(
            (m) => m.VerificationEmailModule,
          ),
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('../registration/registration.module').then((m) => m.RegistrationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
