<ng-template [engBreakpointsClass]="['Desktop']"></ng-template>
<div class="frame">
  <div class="new-block-for-background">
    <img class="logo" src="/assets/img/first-screen/logo.svg" alt="logo" />
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <eng-footer />
  <!-- <eng-footer></eng-footer> -->
</div>
